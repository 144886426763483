import React, { createContext, useContext, useEffect, useRef } from 'react';
import useLocalStorage from 'use-local-storage';
import axios from 'axios';
import { useAuth } from './Auth';

const TimezoneContext = createContext();

const useTimezone = () => useContext(TimezoneContext);

function TimezoneProvider({ children }) {
	const { authTokens, getAuthHeader } = useAuth();

	const isRunOnce = useRef();

	const [timezone, setTimezone] = useLocalStorage('timezone', '');

	useEffect(() => {
		const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		if (!isRunOnce.current && authTokens && currentTimezone !== timezone) {
			isRunOnce.current = true;

			axios
				.post(
					'/api/user/timezone',
					{ currentTimezone },
					{
						headers: getAuthHeader(),
					}
				)
				.then(() => {
					setTimezone(currentTimezone);
				})
				.catch(() => {
					isRunOnce.current = false;
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authTokens]);

	return (
		<TimezoneContext.Provider value={timezone}>
			{children}
		</TimezoneContext.Provider>
	);
}

export { useTimezone, TimezoneProvider };
